<template>
    <div id="manage">
        <div class="common-header">
            <i class="el-icon-arrow-left visit-back" @click="()=>{this.$router.go(-1)}"></i>
            <span>{{researchName}}</span>
        </div>
        <div class="manage-add">
            <!-- <el-button size="mini" type="primary">研究配置</el-button> -->
            <span class="manage-title">研究配置</span>
        </div>
        <div class="manage-content">
            <div class="manage-content_coll">
                <el-card :body-style="{padding:'5px'}">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <span class="content_coll_title">中心管理</span>
                                <el-button
                                        v-authorizeRole="{'itemCode':'center_add','menuContainer':$authorContainer}"
                                        class="content_coll_add"
                                        size="mini"
                                        type="primary"
                                        @click.stop="centerAdd"
                                >添加中心
                                </el-button>
                            </template>
                            <div class="manage-centerOrAdd clearfix">
                                <el-input
                                        class="manage-searchVal"
                                        clearable
                                        size="mini"
                                        v-model="searchVal"
                                        placeholder="请输入中心编号/名称"
                                ></el-input>
                                <el-button
                                        class="manage-searchBtn"
                                        icon="el-icon-search"
                                        size="mini"
                                        type="primary"
                                        @click="handleFilter"
                                >查询
                                </el-button>
                            </div>
                            <!-- 中心管理列表 -->
                            <el-table
                                    v-loading="centerlistLoading"
                                    :data="centerTableData"
                                    style="width: 100%;margin-top:20px"
                                    border
                                    :header-cell-class-name="tableHeaderCn"
                                    :row-class-name="tableRowCn"
                            >
                                <el-table-column prop="centerCode" align="center" label="中心编号"></el-table-column>
                                <el-table-column prop="centerName" align="center" label="中心名称"></el-table-column>
                                <el-table-column prop="mainMan" align="center" label="主研究者"></el-table-column>
                                <el-table-column label="中心状态" align="center">
                                    <template slot-scope="scope">{{scope.row.status===0?'未启用':'启用'}}</template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="100" align="center">
                                    <template slot-scope="scope">
                                        <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
                                        <el-button
                                                v-authorizeRole="{'itemCode':'center_update',menuContainer:$authorContainer}"
                                                type="text" size="small" @click="centerEdit(scope.row)">
                                            <i class="iconfont icon-bianji"></i>
                                        </el-button>
                                        <!--<el-button type="text" size="small" v-authorizeRole="{'itemCode':'center_del',menuContainer:$authorContainer}">
                                            <el-popconfirm title="您确定要删除该中心吗？" @confirm="removeCenter(scope.row)">
                                                <i slot="reference" class="iconfont icon-shanchu"></i>
                                            </el-popconfirm>
                                        </el-button>-->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                        <el-collapse-item name="2">
                            <template slot="title">
                                <span class="content_coll_title">用户管理</span>
                                <el-button     v-authorizeRole="{'itemCode':'user_add',menuContainer:$authorContainer}"
                                               class="content_coll_add"
                                               size="mini"
                                               type="primary"
                                               @click.stop="userAdd('userFormAdd')"
                                >添加用户
                                </el-button>
                            </template>
                            <div class="manage-centerOrAdd clearfix">
                                <el-input
                                        class="manage-searchVal"
                                        clearable
                                        size="mini"
                                        v-model="searchUserVal"
                                        placeholder="请输入用户名/姓名"
                                ></el-input>
                                <el-button
                                        class="manage-searchBtn"
                                        icon="el-icon-search"
                                        size="mini"
                                        type="primary"
                                        @click="handleFilterUser"
                                >查询
                                </el-button>
                            </div>
                            <!-- 用户管理列表 -->
                            <el-table
                                    v-loading="userlistLoading"
                                    :data="userTableData"
                                    style="width: 100%;margin-top:20px"
                                    border
                                    :header-cell-class-name="tableHeaderCn"
                                    :row-class-name="tableRowCn"
                            >
                                <el-table-column prop="userItcode" align="center" label="用户名"></el-table-column>
                                <el-table-column prop="userName" align="center" label="姓名"></el-table-column>
                                <!-- <el-table-column prop="date" align="center" label="用户类型">
                                    <template slot-scope="scope">{{scope.row.typeName}}</template>
                                </el-table-column> -->
                                <el-table-column prop="date" align="center" label="角色类型">
                                    <template slot-scope="scope">{{scope.row.roleName}}</template>
                                </el-table-column>
                                <el-table-column prop="date" align="center" label='用户状态'>
                                    <template slot-scope="scope">{{scope.row.state===1?'激活':'冻结'}}</template>
                                </el-table-column>
                                <el-table-column label="归属" align="center">
                                    <template slot-scope="scope">{{getTypeList(scope.row)}}</template>
                                </el-table-column>
                                <!-- <el-table-column label="用户状态">
                                </el-table-column>-->
                                <el-table-column fixed="right" align="center" label="操作" width="100">
                                    <template slot-scope="scope">
                                        <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
                                        <el-button v-authorizeRole="{'itemCode':'user_update',menuContainer:$authorContainer}"
                                                   type="text" size="small" @click="userEdit(scope.row)">
                                            <i class="iconfont icon-bianji"></i>
                                        </el-button>
                                        <!-- <el-button type="text" size="small">
                                            <el-popconfirm title="您确定要删除该用户吗？" @confirm="removeUser(scope.row)">
                                                <i slot="reference" class="iconfont icon-shanchu"></i>
                                            </el-popconfirm>
                                        </el-button> -->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-collapse-item>
                    </el-collapse>
                </el-card>
            </div>
        </div>
        <!-- 添加中心 -->
        <el-dialog
                :title="textMap[dialogStatus]"
                :visible.sync="centerDialogVisibleAdd"
                @close="isClose('centerFormAdd')"
                width="40%"
                custom-class="center-dialog"
        >
            <el-form
                    size="mini"
                    ref="centerFormAdd"
                    :model="centerForm"
                    :rules="centerRules"
                    label-width="100px"
            >
                <el-form-item label="中心名称" prop="centerName">
                    <el-input placeholder="请输入表中心名称" clearable v-model="centerForm.centerName"></el-input>
                </el-form-item>
                <el-form-item label="中心编号" prop="centerCode">
                    <el-input placeholder="请输入中心编号" clearable v-model="centerForm.centerCode"></el-input>
                </el-form-item>
                <el-form-item label="主研究者" prop="mainMan">
                    <el-input placeholder="请输入主研究者" clearable v-model="centerForm.mainMan"></el-input>
                </el-form-item>
                <el-form-item label="计划入组量" prop="expectCaseNum">
                    <el-input type="number" placeholder="请输入计划入组量" clearable
                              v-model="centerForm.expectCaseNum"></el-input>
                </el-form-item>
                <el-form-item label="中心状态" prop="status">
                    <el-switch
                            :active-value="1"
                            :inactive-value="0"
                            active-text="启用"
                            inactive-text="未启用"
                            v-model="centerForm.status"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="centerDialogVisibleAdd = false">取 消</el-button>
        <el-button
                :loading="loading"
                size="mini"
                type="primary"
                @click="centerAddConfirm('centerFormAdd')"
        >确 定</el-button>
      </span>
        </el-dialog>
        <!-- 添加用户 -->
        <el-dialog
                :title="textMap[dialogStatus]"
                :visible.sync="userDialogVisibleAdd"
                @close="isClose('userFormAdd')"
                width="40%"
                custom-class="center-dialog"
        >
            <div v-if="dialogStatus === 'createUser' || dialogStatus === 'createNewUser'">
                <el-tabs v-model="whatTab" type="card" @tab-click="handleClickTab">
                    <el-tab-pane label="添加用户" name="createUser"></el-tab-pane>
                    <el-tab-pane label="创建用户" name="createNewUser"></el-tab-pane>
                </el-tabs>
            </div>

            <el-form :style="{'padding-left':'10px'}"
                     size="mini"
                     ref="userFormAdd"
                     :model="userForm"
                     :rules="userRules"
                     label-width="100px"
                     label-position="left"
            >
                <el-form-item label="用户名" prop="userName" v-if="whatTab === 'createUser'">
                    <el-select
                            :disabled = "dialogStatus === 'updateUser'"
                            :state="dialogStatus === 'updateUser'"
                            @change="userChange"
                            :style="{'width':'100%'}"
                            v-model="userForm.userName"
                            placeholder="请选择用户"
                    >
                        <el-option
                                v-for="item in userList"
                                :key="item.userItcode"
                                :label="item.userItcode"
                                :value="item.userItcode"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户名" prop="userItcode" v-if="dialogStatus == 'createNewUser'">
                    <el-input placeholder="请输入用户名" v-model="userForm.userItcode" clearable></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="mainName">
                    <el-input :disabled = 'formatterPro()' placeholder v-model="userForm.mainName"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="userPhone">
                    <el-input :disabled = 'formatterPro()' placeholder v-model="userForm.userPhone"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="userEmail">
                    <el-input :disabled = 'formatterPro()' placeholder v-model="userForm.userEmail"></el-input>
                </el-form-item>
                <el-form-item label="企业/单位" prop="companyName">
                    <el-input :disabled = 'formatterPro()' placeholder v-model="userForm.companyName"></el-input>
                </el-form-item>
                <!-- <el-form-item label="用户类型" prop="userType">
                    <el-select
                            :disabled = 'formatterPro()'
                            :style="{'width':'100%'}"
                            v-model="userForm.userType"
                            placeholder="请选择类型"
                    >
                        <el-option
                                v-for="item in userRoles"
                                :key="item.pkId"
                                :label="item.roleName"
                                :value="item.pkId"
                        ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="用户归属" prop="type" v-if="whatTab == 'createUser'">
                    <el-radio-group v-model="userForm.type"  @change = 'roleTypeChange'>
                        <el-radio :label="1">研究</el-radio>
                        <el-radio :label="2">中心</el-radio>
                    </el-radio-group>
                    <el-select
                            v-if="userForm.type===2"
                            :style="{'margin-left':'10px'}"
                            v-model="centerArray"
                            multiple
                            placeholder="请选择所属中心"
                    >
                        <el-option
                                v-for="item in centerList"
                                :key="item.centerKey"
                                :label="item.centerName"
                                :value="item.centerKey"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="角色类型" prop="roleType" v-if="dialogStatus !== 'createNewUser'">
                    <el-select
                            :style="{'width':'100%'}"
                            v-model="userForm.roleType"
                            placeholder="请选择角色类型"
                    >
                        <el-option
                                v-for="item in roleList"
                                :key="item.pkId"
                                :label="item.roleName"
                                :value="item.pkId"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="账户状态" prop="state" v-if="dialogStatus == 'createUser'|| dialogStatus == 'updateUser'">
                    <el-radio-group v-model="userForm.state">
                        <el-radio :label="0">冻结</el-radio>
                        <el-radio :label="1">激活</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="账户状态" prop="disabled" v-else>
                    <el-radio-group v-model="userForm.disabled">
                        <el-radio :label="0">冻结</el-radio>
                        <el-radio :label="1">激活</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="通知用户" prop="noticeType"  v-if="dialogStatus == 'createNewUser'">
                    <el-checkbox-group v-model="userForm.noticeType">
                        <!-- <el-checkbox :label="1">短信</el-checkbox> -->
                        <el-checkbox :label="2">邮件</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <!-- <el-form-item label="用户状态" prop="type">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    active-text="启用"
                    inactive-text="未启用"
                    v-model="userForm.type"
                  ></el-switch>
                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="userDialogVisibleAdd = false">取 消</el-button>
        <el-button size="mini" type="primary" :loading="addBtnLoading" @click="userAddConfirm('userFormAdd')">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {localData} from "../utils/storage";
    import {
        addSiteCenter,
        // addSRRelationMapping,
        getAllCenter,
        updateSiteCenter,
        // updateSRRelationMapping,
        deleteSiteCenter,
        getResearchUser,
        addUser,
        getUserList,
        getUserCenter,
        deleteUser,
        updateUsers,
        getRoleByType,
        getUserRole,
        userInfoByName,
        pullUser,
        editUser
    } from "@/api/manage";

    export default {
        name: "Manage",
        data() {
            const validateCenter = (rule, value, callback) => {
                // if (value === 0) {
                //   if (!this.centerString) {
                //     callback(new Error("请选择一个所属研究"));
                //   } else {
                //     callback();
                //   }
                // } else
                if (value === 2) {
                    if (this.centerArray.length === 0) {
                        callback(new Error("请选择所属中心"));
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            };
            let amountValid = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入样本量!'));
                } else if (value > 1000000000) {
                    callback(new Error('样本量最大为1000000000!'));
                } else
                    callback();
            };
            return {
                userId:'',
                addBtnLoading:false,
                whatTab:'createUser',
                researchCode: this.$route.query.researchCode,
                researchName: this.$route.query.researchName,
                dialogStatus: "",
                textMap: {
                    updateCenter: "编辑中心",
                    createCenter: "添加中心",
                    updateUser: "编辑用户",
                    createUser: "添加用户",
                    createNewUser:'创建用户'
                },
                centerDialogVisibleAdd: false,
                centerForm: {
                    centerCode: "",
                    centerName: "",
                    mainMan: "",
                    status: 1,
                    expectCaseNum: 0
                },
                centerRules: {
                    centerName: [
                        {required: true, message: "请输入中心名称", trigger: "blur"},
                        {max: 255, message: "最多不超过255个字", trigger: "blur"}
                    ],
                    centerCode: [
                        {required: true, message: "请输入中心编号", trigger: "blur"},
                        {max: 255, message: "最多不超过255个字", trigger: "blur"}
                    ],
                    mainMan: [
                        {required: true, message: "请输入主研究者", trigger: "blur"},
                        {max: 255, message: "最多不超过255个字", trigger: "blur"}
                    ],
                    status: [{required: true}],
                    expectCaseNum: [
                        {required: true, message: "请输入计划入组量", trigger: "blur"},
                        {validator: amountValid, trigger: 'change'},

                    ]
                },
                loading: false,
                searchVal: "",
                activeNames: ["1", "2"],
                centerTableData: [],
                centerlistLoading: false,
                // 用户
                userlistLoading: false,
                searchUserVal: "",
                userList: [],
                userRoles:[],
                centerList: [],
                userTableData: [],
                userDialogVisibleAdd: false,
                centerString: "",
                centerArray: [],
                roleList:[],
                userForm: {
                    userId: "",
                    mainName: "",
                    type: 1,
                    state:1,
                    disabled: 1,
                    userName:'',
                    userPhone: '',
                    userEmail: '',
                    companyName: '',
                    userType: '',
                    userItcode:'',
                    noticeType:[2]
                },
            };
        },
        computed: {
            userRules: function(){
                const validateCenter = (rule, value, callback) => {
                    // if (value === 0) {
                    //   if (!this.centerString) {
                    //     callback(new Error("请选择一个所属研究"));
                    //   } else {
                    //     callback();
                    //   }
                    // } else
                    if (value === 2) {
                        if (this.centerArray.length === 0) {
                            callback(new Error("请选择所属中心"));
                        } else {
                            callback();
                        }
                    } else {
                        callback();
                    }
                };
                if (this.whatTab === 'createUser') {
                    let createUser = {
                        userName: [
                            {required: true, message: "请选择用户名", trigger: "change"}
                        ],
                        type: [
                            {required: true, message: "请选择所属中心", trigger: "blur"},
                            {validator: validateCenter, trigger: "change"}
                        ],
                        // state: [
                        //     {required: true, message: "请选择用户类型", trigger: "blur"}
                        // ],
                        roleType: [
                            {required: true, message: "请选择角色类型", trigger: "change"}
                        ],
                    }
                    // console.log(createUser);
                    return createUser
                } else {
                    let newCreateUser = {
                        noticeType: [
                            {required: true, message: "请选择通知方式", trigger: "change"}
                        ],
                        userItcode: [
                            {required: true, message: "请选输入用户名", trigger: "change"}
                        ],
                        userPhone: [
                            { required: true, message: '请输入手机号', trigger: 'change' },
                            { pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: '请输入正确的手机号码或者座机号'},
                        ],
                        userEmail: [
                            { required: true, message: '请输入邮箱地址', trigger: 'change' },
                            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']},
                        ],
                        companyName: [
                            {required: true, message: "请添加企业/单位", trigger: "change"}
                        ],
                        // userName: [
                        //     {required: true, message: "请输入用户名", trigger: "blur"}
                        // ],
                        mainName: [
                            {required: true, message: "请输入姓名", trigger: "change"}
                        ],
                        // userType: [
                        //     {required: true, message: "请选择用户类型", trigger: "change"}
                        // ],
                        disabled: [
                            {required: true, message: "请选择账户类型", trigger: "change"}
                        ],
                    }
                    // console.log(newCreateUser);
                    return newCreateUser

                }
            }
        },
        watch: {
            // userForm:{
            //     deep:true,
            //     handler(n,o){
            //         console.log(222);
            //         if (n.type !== o.type && this.dialogStatus !== 'createNewUser') {
            //             this.getRoleByType()
            //             n.roleType = ''
            //         }
            //     }
            // },
            // 'userForm.type':function (n,o) {
            //     console.log(n);
            //     if (n!=o && this.dialogStatus !== 'createNewUser') {
            //         this.getRoleByType()
            //     }
            // }
        },
        created() {
            this.getCenterList();
            this.getUsersList();
            this.getRoleByType()
            let obj = {researchCode:this.researchCode,versionCode:JSON.parse(localStorage.getItem('versionCode'))}
            getUserCenter(obj).then(res => {
                this.centerList = res.data.dataList;
            });
        },
        methods: {
            roleTypeChange(){
                this.getRoleByType()
                this.$set(this.userForm,'roleType','')
            },
            getRoleByType(){
                getRoleByType({type:this.userForm.type}).then((res)=>{
                    // console.log(res);
                    this.roleList = res.data
                }).catch((err)=>{
                    console.log(err);
                })
            },
            formatterPro(){
                return this.whatTab === 'createUser'
            },
            handleClickTab(v){
                this.dialogStatus = v.name
                // console.log(this.userForm);
                this.$refs.userFormAdd.clearValidate();
                if (v.name === 'createNewUser') {
                    localStorage.setItem('userForm',JSON.stringify(this.userForm))
                    let newObj = {
                        environment: 1,
                        userName:'',
                        userPhone: '',
                        userEmail: '',
                        companyName: '',
                        userType: '',
                        userItcode:'',
                        disabled:1,
                        mainName:'',
                        noticeType:[2]
                    }
                    this.userForm = newObj
                }else {
                    this.userForm = {... JSON.parse(localStorage.getItem('userForm'))}
                }

            },
            tableHeaderCn() {
                return "manage-table_header";
            },
            tableRowCn() {
                return "manage-table_row";
            },
            getCenterList(val) {
                this.centerlistLoading = true;
                if (val === "add") {
                    this.searchVal = "";
                }
                getAllCenter(this.researchCode, this.searchVal,JSON.parse(localStorage.getItem('versionCode')))
                    .then(res => {
                        this.centerTableData = res.data.dataList;
                        this.centerlistLoading = false;
                    })
                    .catch(error => {
                        this.centerlistLoading = false;
                    });
            },
            handleFilter() {
                this.getCenterList();
            },
            centerAdd() {
                this.dialogStatus = "createCenter";
                this.centerDialogVisibleAdd = true;
            },
            //编辑中心
            centerEdit(item) {
                this.centerForm = {
                    pkId: item.pkId,
                    centerCode: item.centerCode,
                    centerName: item.centerName,
                    mainMan: item.mainMan,
                    status: item.status,
                    expectCaseNum: item.expectCaseNum
                };
                this.dialogStatus = "updateCenter";
                this.centerDialogVisibleAdd = true;
            },
            centerAddConfirm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        this.loading = true;
                        this.$set(this.centerForm, "researchCode", this.researchCode);
                        let obj  = {... this.centerForm}
                        obj.versionCode = JSON.parse(localStorage.getItem('versionCode'))
                        if (this.dialogStatus === "updateCenter") {
                            updateSiteCenter(obj)
                                .then(res => {
                                    this.$message({
                                        type: "success",
                                        message: "数据修改成功!"
                                    });
                                    this.getCenterList();
                                    this.getUsersList();
                                    this.centerDialogVisibleAdd = false;
                                    this.loading = false;
                                })
                                .catch(error => {
                                    this.loading = false;
                                });
                        } else if (this.dialogStatus === "createCenter") {
                            addSiteCenter(obj)
                                .then(res => {
                                    this.$message({
                                        type: "success",
                                        message: "数据插入成功!"
                                    });
                                    this.getCenterList("add");
                                    this.centerDialogVisibleAdd = false;
                                    this.loading = false;
                                })
                                .catch(error => {
                                    this.loading = false;
                                });
                        }
                    } else {
                        this.loading = false;
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            removeCenter(row) {
                const obj = {
                    pkId: row.pkId,
                    centerKey: row.centerKey
                };
                deleteSiteCenter(obj)
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: "res.data"
                        });
                        this.getCenterList();
                        this.getUsersList();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            // 用户
            getUsersList(val) {
                this.userlistLoading = true;
                if (val === "add") {
                    this.searchUserVal = "";
                }
                let env = localData('get', 'operatingEnv');
                getResearchUser(
                    { code: this.researchCode,
                        environment: env=== 'test'? '1': '2',
                        itcode:this.searchUserVal || ''
                    }).then(res => {
                    this.userTableData = res.data;
                    this.userlistLoading = false;
                }).catch(()=>{
                    this.userlistLoading = false;
                });
                // getUserList(this.researchCode, this.searchUserVal)
                //     .then(res => {
                //         this.userTableData = res.data.dataList;
                //         this.userlistLoading = false;
                //     })
                //     .catch(error => {
                //         this.userlistLoading = false;
                //     });
            },
            handleFilterUser() {
                this.getUsersList();
            },
            getUser() {

                userInfoByName({itcode:''}).then(res => {
                    this.userList = res.data;
                });
                getUserRole({}).then((res)=>{
                    console.log(res);
                    this.userRoles = res.data
                }).catch((err)=>{
                    console.log(err);
                })
            },
            userChange(val) {
                // console.log(val);
                userInfoByName({itcode:val}).then(res => {
                    const newArr = res.data
                    this.userForm.mainName = newArr[0].userName;
                    this.userForm.userId = newArr[0].userId;

                    this.userForm.userEmail = newArr[0].userEmail;
                    this.userForm.userPhone = newArr[0].userPhone;
                    this.userForm.companyName = newArr[0].companyName;
                    // this.userForm.type = newArr[0].type || 1;
                    // this.userForm.state = newArr[0].state || 1;
                    this.userForm.roleId = newArr[0].roleId;
                    this.userForm.userType = newArr[0].roleName;
                    this.userId =  newArr[0].pkId
                });
            },
            userAdd(formName) {
                this.roleList = []
                this.$refs[formName] && this.$refs[formName].clearValidate();
                this.dialogStatus = "createUser";
                this.whatTab ='createUser'
                this.getUser();
                this.getRoleByType()
                let obj = {researchCode:this.researchCode,versionCode:JSON.parse(localStorage.getItem('versionCode'))}
                getUserCenter(obj).then(res => {
                    this.centerList = res.data.dataList;
                });
                this.userDialogVisibleAdd = true;
            },
            userEdit(item) {
                this.getUser();
                let obj = {researchCode:this.researchCode,versionCode:JSON.parse(localStorage.getItem('versionCode'))}
                getUserCenter(obj).then(res => {
                    this.centerList = res.data.dataList;
                });

                //编辑用户
                let arr = [];
                if (item.type === 2) {
                    this.centerArray = item.centerCodes.split(',')
                }

                this.userForm = {
                    userId: item.userId,
                    userName: item.userItcode,
                    mainName: item.mainName,
                    type: item.type,
                    roleType:item.roleId,
                    state:item.state,
                    pkId:item.pkId
                };
                this.dialogStatus = "updateUser";
                this.userDialogVisibleAdd = true;
                this.userChange(item.userItcode)

                this.getRoleByType()
            },
            userAddConfirm(formName) {
                // console.log(this.userForm);
                let env = localData('get', 'operatingEnv');
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        let list = [];
                        // if (this.userForm.type === 0) {
                        //   list = this.centerList.filter(
                        //     item => item.centerKey === this.centerString
                        //   );
                        // } else
                        if (this.userForm.type === 2) {
                            list = this.centerList.filter(
                                item => this.centerArray.indexOf(item.centerKey) > -1
                            );
                        }
                        this.$set(this.userForm, "list", list);
                        this.$set(this.userForm, "code", this.researchCode);
                        let obj = {
                            environment: env=== 'test'? '1': '2',
                            pkId: this.userForm.pkId,
                            code: this.researchCode,
                            type: this.userForm.type,
                            roleId: this.userForm.roleType,
                            state: this.userForm.state,
                            centerCodes:this.centerArray.join(',')
                        }
                        this.addBtnLoading = true
                        if (this.dialogStatus === "updateUser") {  // 编辑
                            editUser(obj)
                                .then(res => {
                                    this.$message({
                                        type: "success",
                                        message: "数据修改成功!"
                                    });
                                    this.getUsersList();
                                    this.userDialogVisibleAdd = false;
                                    this.loading = false;
                                    this.addBtnLoading = false
                                })
                                .catch(error => {
                                    this.loading = false;
                                    this.addBtnLoading = false
                                });
                        } else if (this.dialogStatus === "createUser") { // 添加
                            let newObj = {
                                environment: env=== 'test'? '1': '2',
                                code:this.researchCode,
                                state: this.userForm.state,
                                roleId: this.userForm.roleType,
                                type: this.userForm.type,
                                userId: this.userId,
                                centerCodes:this.centerArray.join(',')
                            }
                            pullUser(newObj)
                                .then(res => {
                                    this.$message({
                                        type: "success",
                                        message: "数据插入成功!"
                                    });
                                    this.getUsersList("add");
                                    this.userDialogVisibleAdd = false;
                                    this.loading = false;
                                    this.addBtnLoading = false
                                })
                                .catch(error => {
                                    this.loading = false;
                                    this.addBtnLoading = false
                                });
                        } else { // 新建
                            // console.log('新建',this.userForm);

                            let newObj = {
                                companyName: this.userForm.companyName,
                                disabled: ''+this.userForm.disabled,
                                userItcode: this.userForm.userItcode,
                                roleId: this.userForm.userType,
                                userPhone: this.userForm.userPhone,
                                userEmail: this.userForm.userEmail,
                                noticeType: this.userForm.noticeType.join(','),
                                userName: this.userForm.mainName,
                                environment: env=== 'test'? '1': '2'
                            }
                            addUser(newObj)
                                .then(res => {
                                    this.$message({
                                        type: "success",
                                        message: "数据插入成功!"
                                    });
                                    this.getUsersList("add");
                                    this.userDialogVisibleAdd = false;
                                    this.loading = false;
                                    this.addBtnLoading = false
                                })
                                .catch(error => {
                                    this.loading = false;
                                    this.addBtnLoading = false
                                });
                        }
                    } else {
                        this.loading = false;
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            isClose(formName) {
                this.dialogStatus = "";
                this.whatTab ='createUser'
                this.$refs[formName] && this.$refs[formName].resetFields();
                this.centerForm = {
                    centerCode: "",
                    centerName: "",
                    mainMan: "",
                    status: 0
                };
                this.centerString = "";
                this.centerArray = [];
                this.userForm = {
                    userId: "",
                    userName: "",
                    mainName: "",
                    type: 1,
                    state:1,
                    disabled: 1,
                    noticeType:[]
                };
            },
            getTypeList(row) {
                // 所属
                if (row.type === 1) {
                    return "研究";
                } else if (row.type === 2) {
                    return row.centerName
                }
            },
            removeUser(row) {
                const obj = {
                    userId: row.userId,
                    researchCode: this.researchCode
                };
                deleteUser(obj)
                    .then(res => {
                        this.$message({
                            type: "success",
                            message: res.data
                        });
                        this.getUsersList();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            // centerEditConfirm(formName) {
            //   this.$refs[formName].validate(valid => {
            //     if (valid) {
            //       this.loading = true;
            //       this.$set(this.centerForm, "researchCode", this.researchCode);
            //       updateSiteCenter(this.centerForm)
            //         .then(res => {
            //           this.$message({
            //             type: "success",
            //             message: "数据修改成功!"
            //           });
            //           this.getCenterList();
            //           this.centerDialogVisibleAdd = false;
            //           this.loading = false;
            //           // this.$set(this.centerForm, "researchName", this.researchName);
            //           // updateSRRelationMapping(this.centerForm)
            //           //   .then(response => {
            //           //     this.$message({
            //           //       type: "success",
            //           //       message: "数据修改成功!"
            //           //     });
            //           //     this.getCenterList();
            //           //     this.centerDialogVisibleAdd = false;
            //           //     this.loading = false;
            //           //   })
            //           //   .catch(error => {
            //           //     this.loading = false;
            //           //   });
            //         })
            //         .catch(error => {
            //           this.loading = false;
            //         });
            //     } else {
            //       this.loading = false;
            //       console.log("error submit!!");
            //       return false;
            //     }
            //   });
            // },
        }
    };
</script>

<style scoped lang="scss">
    .icon-bianji{
        color: #2c5c89 !important;
    }
    .icon-shanchu{
        color: #2c5c89 !important;
    }
    /deep/.el-table thead{
        color: #000;
    }
    #manage {
        height: 100%;
        // background: rgba(73, 150, 255, 0.14);
        background: #fff;
        .manage-add {
            // text-align: left;
            height: 40px;
            line-height: 40px;
            .manage-title {
                height: 16px;
                font-size: 16px;
                color: #212121;
                margin-left: 16px;
                margin-right: 32px;
            }
            button {
                padding: 0 16px;
                height: 24px;
                line-height: 24px;
                font-size: 12px;
                color: #044e9a;
                background: #b3dfff;
                border-radius: 2px;
                margin: 8px 16px;
                border: none;
            }
        }
        .manage-content {
            position: absolute;
            top: 130px;
            bottom: 16px;
            left: 0px;
            right: 0px;
            background: #fff;
            // padding-top: 24px;
            overflow-y: auto;
            .manage-searchVal {
                width: 240px;
                margin: 0 20px 0 10px;
            }
            .manage-searchBtn {
                color: #FFF;
                background: #2c5c89;
                border-radius: 2px;
                border: none;
            }
            .manage-content_coll {
                position: relative;
                padding: 10px;
                .content_coll_title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #212121;
                    line-height: 24px;
                    margin-left: 42px;
                }
                .content_coll_add {
                    position: absolute;
                    right: 30px;
                    width: 120px;
                    height: 32px;
                    font-size: 16px;
                    color: #fff;
                    background: #2c5c89;
                    border-color: #2c5c89;
                }
            }
        }
    }
    .el-collapse-item__arrow {
        margin-left: 10px;

    }
    /deep/.el-collapse-item__header{
        height: 60px;
        line-height: 60px;
    }
</style>
<style lang="scss">
    .manage-content_coll .el-collapse-item__arrow {
        position: absolute;
        font-size: 24px;
        color: #8a8c91;
        margin-left: 10px;
    }

    .manage-content .el-collapse,
    .manage-content .el-collapse-item__header,
    .manage-content .el-collapse-item__wrap {
        border-bottom: 1px solid #ededed;
    }
    .el-collapse{
        border: none;
    }

    .manage-table_header {
        height: 48px;
        background-color: #f5f5f5 !important;
        border-right: none !important;
    }

    .manage-table_row td {
        height: 42px;
        padding: 0;
    }

    #manage {
        .el-collapse-item__header:hover {
            background: #F5F7FA;
        }

        .el-collapse-item__wrap {
            margin-top: 10px;
        }
    }
</style>
