import Vue from 'vue'
import request from './axios'
// 研究中心
export function addSiteCenter(data) {
  return request({
    url: '/api/tSiteCenter/saveBean',
    method: 'POST',
    data,
  })
}

export function getAllCenter(researchCode, search,versionCode) {
  return request({
    url: '/api/tSiteCenter/getAllCenter',
    method: 'GET',
    params: {
      researchCode,
      search,
      versionCode
    },
  })
}
export function updateSiteCenter(data) {
  return request({
    url: '/api/tSiteCenter/updateBean',
    method: 'POST',
    data,
  })
}

export function deleteSiteCenter(data) {
  return request({
    url: '/api/tSiteCenter/deleteBeanById',
    method: 'POST',
    data,
  })
}
// 用户
export function getResearchUser(data) {  //用户名列表
  return request({
    url: '/api/base/getResearchUser',
    method: 'POST',
    data
  })
}
export function addUser(data) {
  return request({
    url: '/api/base/addUser',
    method: 'POST',
    data,
  })
}
export function getRoleByType(data) {
  return request({
    url: '/api/base/getRoleByType',
    method: 'POST',
    data,
  })
}
export function userInfoByName(data) {
  return request({
    url: '/api/base/userInfoByName',
    method: 'POST',
    data,
  })
}
export function getUserRole(data) {
  return request({
    url: '/api/base/getUserRole',
    method: 'POST',
    data,
  })
}
export function pullUser(data) {
  return request({
    url: '/api/base/pullUser',
    method: 'POST',
    data,
  })
}
export function getUserList(researchCode, search) {
  return request({
    url: '/api/tSiteRelationMapping/lookForAll',
    method: 'GET',
    params: {
      researchCode,
      search
    },
  })
}
export function getUserCenter(params,) {  //所属中心列表
  return request({
    url: '/api/tSiteCenter/getCenterList',
    method: 'GET',
    params
  })
}
export function deleteUser(data) {
  return request({
    url: '/api/tSiteRelationMapping/deleteBean',
    method: 'POST',
    data,
  })
}
export function editUser(data) {
  return request({
    url: '/api/base/editUser',
    method: 'POST',
    data,
  })
}

export function updateUsers(data) {
  return request({
    url: '/api/tSiteRelationMapping/updateBean',
    method: 'POST',
    data,
  })
}